import SimpleParallax from 'simple-parallax-js/dist/vanilla/simpleParallaxVanilla.umd.js';

//You can also choose to apply the parallax on multiple images:

var images = document.querySelectorAll('.slider__img');

new SimpleParallax(images, {
    scale: 1.4,
    orientation: 'down',
});
